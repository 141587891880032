import { Component, Vue } from "vue-property-decorator";
import VueCookies from 'vue-cookies'
import { GET_PROFILE_AGENT, EDIT_AGENT_NEW, GET_ROYALTY_SETTING_BY_ID, GET_HISTORY_AGENT, GET_HISTORY_AGENT_BY_DATE, CHANGE_CHILD_AGENT_PASSWORD } from "../../../../api/account"
import { FLOD_LIMIT_LIST, HOLDEM_LIMIT_LIST, SIXPLUS_HOLDEMLIMIT_LIST, PLO4_LIMIT_LIST, PLO5_LIMIT_LIST } from "../Component/gameTypeLimit.component"
import vSelect from 'vue-select';
import Model from "@/models/Model";
import { ShareholderObj } from "../Component/dto.component";
import VModal from 'vue-js-modal'
import moment from 'moment';

Vue.use(VModal, { componentName: 'modal' })
Vue.use(VueCookies)

import 'vue-select/dist/vue-select.css';

@Component({
    components: {
        vSelect
    },
    filters: {
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        date(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        },
        sCurrency(value: any) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
})

class EditAgent extends Vue {
    $axios: any;
    public role = ""
    public updateDate = "";
    private profile: any = {}
    public parentRoyaltySetting: any = {}
    public historyData: any = {}
    private userId = ""
    private cookieData: any = Vue.$cookies.get("login")
    public displayShow = true

    private flodpIndex = [ "flodOurPercentage", "flodHoldPercentage"]
    private holdempIndex = [ "holdemOurPercentage", "holdemHoldPercentage"]
    private sixholdempIndex = [ "sixholdemOurPercentage", "sixholdemHoldPercentage"]
    private plo4pIndex = [ "plo4OurPercentage", "plo4HoldPercentage"]
    private plo5pIndex = [ "plo5OurPercentage", "plo5HoldPercentage"]


    public allPercentageVal = {

        flodHoldPercentage: 0,
        flodOurPercentage: 0,

        holdemHoldPercentage: 0,
        holdemOurPercentage: 0,

        sixholdemHoldPercentage: 0,
        sixholdemOurPercentage: 0,

        plo4HoldPercentage: 0,
        plo4OurPercentage: 0,

        plo5HoldPercentage: 0,
        plo5OurPercentage: 0,

    }


    public dataAgentnew: ShareholderObj = {
        username: "",
        name: "",
        password: "",
        confirmPassword: "",
        email: "",
        phone: "",
        referrer: "",
        remark: "",
        currency: "",
        creditLimit: 0,
        typeBusiness:  "B2B",
        typeAgentAPI: "",

        suspend: false,
        lock: false,

        flodStatus: false,
        flodOurPercentage: 0,
        flodHoldPercentage: 0,
        flodLimit: 0,
        flodLimitArr: [],

        holdemStatus: false,
        holdemOurPercentage: 0,
        holdemHoldPercentage: 0,
        holdemLimit: 0, 
        holdemLimitArr: [],
        holdemBOF: false,

        sixholdemStatus: false,
        sixholdemOurPercentage: 0,
        sixholdemHoldPercentage: 0,
        sixholdemLimit: 0, 
        sixholdemLimitArr: [],

        plo4Status: false,
        plo4OurPercentage: 0,
        plo4HoldPercentage: 0,
        plo4Limit: 0, 
        plo4LimitArr: [],

        plo5Status: false,
        plo5OurPercentage: 0,
        plo5HoldPercentage: 0,
        plo5Limit: 0,
        plo5LimitArr: []

    }

    private pageSpinStyle = {
        color: '#CFA137',
        loader: 'spinner',
        width: 145,
        height: 235,
        backgroundColor: '#000000',
        opacity: 0.5,
    }

    public flod_limit_list = FLOD_LIMIT_LIST
    public holdem_limit_list = HOLDEM_LIMIT_LIST
    public sixplus_holdemlimit_list = SIXPLUS_HOLDEMLIMIT_LIST
    public plo4_limit_list = PLO4_LIMIT_LIST
    public plo5_limit_list = PLO5_LIMIT_LIST

    public historyLists = []
    public historyLoading = true;

    public flagDisplayAsPassword = {
        default: true,
        confirm: true
    }

    // private royaltySetting: any = []
    // private typeBusiness = ''
    // private typeAgent = ''
    // private typeAgentAPI = ''
    private model: Model;
    private currencyArray: any = []

    public productPercentSelectionMaster = {
        allinorfold: [],
        holdem: [],
        sixplus: [],
        plo4: [],
        plo5: [],
    }

    public disabledInputMiniGame = {
        holdemBOF: true
    }

    public limitListHistory: any = []

    constructor() {
        super();
        this.model = new Model();
    }

    async mounted() {
        
        const loader = this.$loading.show(this.pageSpinStyle);
        this.userId = this.$route.query.id.toString()
        await this.getRoyaltySetting()
        await this.getProfile(this.userId)
        await this.initData()
        await this.setRate()
        this.addRouteName()
        loader.hide()

    }

    public async getRoyaltySetting() {
        this.parentRoyaltySetting = await GET_ROYALTY_SETTING_BY_ID(this.cookieData.realUsername)
        this.parentRoyaltySetting = this.parentRoyaltySetting[0].royaltySetting.products
    }

    private async getProfile(username: string) {
        const result = await GET_PROFILE_AGENT(username)
        this.role = this.cookieData.roles
        this.profile = result[0]
        this.dataAgentnew['username'] = this.profile.username
        this.dataAgentnew['name'] = this.profile.name
        this.dataAgentnew['lock'] = this.profile.lock
        this.dataAgentnew['suspend'] = this.profile.suspend
        this.dataAgentnew['email'] = this.profile.information.contact.email
        this.dataAgentnew['phone'] = this.profile.information.contact.phone
        this.dataAgentnew['referrer'] = this.profile.information.other.referer
        this.dataAgentnew['remark'] = this.profile.information.other.remark
        this.dataAgentnew['creditLimit'] = 0
        this.dataAgentnew['currency'] = process.env.VUE_APP_CURRENCY_USE
        this.dataAgentnew['typeAgent'] = this.profile.typeAgent
        this.dataAgentnew['typeAgentAPI'] =  this.profile.typeAgentAPI
        this.dataAgentnew['typeBusiness'] = this.profile.typeBusiness

        const rawData = await GET_HISTORY_AGENT(username.toString())
        this.historyLists = rawData.data

        const hasMiniGameBOF = this.parentRoyaltySetting.holdem.miniGame?.includes("BOF") || false
        if(hasMiniGameBOF) this.disabledInputMiniGame.holdemBOF = false

        this.dataAgentnew.holdemBOF = this.profile.royaltySetting.products.holdem.miniGame?.includes("BOF") || false
    }

    public async initData() {
        const currencyUse = process.env.VUE_APP_CURRENCY_USE
        this.currencyArray.push({ label: `${currencyUse}`, value: `${currencyUse}` });
        this.dataAgentnew['parentName'] = this.cookieData.realUsername
        this.dataAgentnew['parentId'] = this.cookieData.realUsername
    }

    public findLocalGameTypeVariableName( objName: string): any{
        const localProductObj = { 
            allinorfold: { 
                status: "flodStatus", 
                ourPercent: "flodOurPercentage", 
                holdPercent: "flodHoldPercentage", 
                limit: "flodLimitArr",
                percentSelection: this.flodpIndex
            },
            holdem: { 
                status: "holdemStatus", 
                ourPercent: "holdemOurPercentage", 
                holdPercent: "holdemHoldPercentage", 
                limit: "holdemLimitArr",
                percentSelection: this.holdempIndex
            },
            sixplus: { 
                status: "sixholdemStatus", 
                ourPercent: "sixholdemOurPercentage", 
                holdPercent: "sixholdemHoldPercentage", 
                limit: "sixholdemLimitArr",
                percentSelection: this.sixholdempIndex
            },
            plo4: { 
                status: "plo4Status", 
                ourPercent: "plo4OurPercentage", 
                holdPercent: "plo4HoldPercentage", 
                limit: "plo4LimitArr",
                percentSelection: this.plo4pIndex
            },
            plo5: { 
                status: "plo5Status", 
                ourPercent: "plo5OurPercentage", 
                holdPercent: "plo5HoldPercentage", 
                limit: "plo5LimitArr",
                percentSelection: this.plo5pIndex
            },
        }

        for (const [key, value] of Object.entries(localProductObj)) {
            if(key == objName){
                return value
            }
        }
        return false
    }

    public async setRate() {        
        for (const [objName, productSetting] of Object.entries(this.profile.royaltySetting.products)) {
            const localValName = this.findLocalGameTypeVariableName(objName)
            productSetting
            if(!localValName){
                continue
            }
            
            this.setEachProductSetting(objName, localValName, productSetting)
        }
    }

    public setEachProductSetting(objName: string, localValName: any, productSetting: any){
        this.dataAgentnew[localValName.status] = productSetting.enable
        this.dataAgentnew[localValName.limit] = productSetting.limit
        this.productPercentSelectionMaster[objName] = this.createPercentageList(productSetting.receiveRate)
        this.allPercentageVal[localValName.percentSelection[0]] = this.productPercentSelectionMaster[objName][this.calOurRate(productSetting.receiveRate, productSetting.rate)]
        this.allPercentageVal[localValName.percentSelection[1]] = this.productPercentSelectionMaster[objName][productSetting.rate] 
    }

    public createPercentageList( maxPercent: number): any[] {
        const arr: any = []
        for (let i = 0; i <= maxPercent; i ++) {
            arr.push({label:`${i}%`, value: i});
        }
        return arr
    }

    public calOurRate(receiveRate: number, rate: number): number {
        return receiveRate - rate
    }

    private addRouteName() {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    public isGameRoyaltySet(typeName: string): boolean{
        if(typeof this.profile.royaltySetting.products[typeName] == "object"){
            return true
        }
        else{
            return false
        }
    }

    public toggleShowPassword(name: string) {
        if (name == 'default') {
            this.flagDisplayAsPassword.default = !this.flagDisplayAsPassword.default;
        }
    }

    public onlyNumber(event: any) {
        const keyCode = (event.keyCode ? event.keyCode : event.which);
        if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
            event.preventDefault();
        }
    }

    public clsAlphaNoOnly(event: any) {  // Accept only alpha numerics, no special characters 
        const regex = new RegExp("^[a-zA-Z0-9]+$");
        const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (regex.test(str)) {
            return true;
        }

        event.preventDefault();
        return false;
    }

    public clsAlphaTHNoOnly(event: any) {  // Accept only alpha numerics, no special characters 
        const regex = new RegExp("^[ก-๏a-zA-Z0-9]+$");
        const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (regex.test(str)) {
            return true;
        }

        event.preventDefault();
        return false;
    }

    public changePercentage(key: string): void {

        if(key == this.flodpIndex[0] || key == this.flodpIndex[1]){
            this.switchPercentage(key,this.allPercentageVal[this.flodpIndex[0]],this.allPercentageVal[this.flodpIndex[1]], this.flodpIndex, this.productPercentSelectionMaster.allinorfold)
        }
        else if(key == this.holdempIndex[0] || key == this.holdempIndex[1]){
            this.switchPercentage(key,this.allPercentageVal[this.holdempIndex[0]],this.allPercentageVal[this.holdempIndex[1]], this.holdempIndex, this.productPercentSelectionMaster.holdem)
        }
        else if(key == this.sixholdempIndex[0] || key == this.sixholdempIndex[1]){
            this.switchPercentage(key,this.allPercentageVal[this.sixholdempIndex[0]],this.allPercentageVal[this.sixholdempIndex[1]], this.sixholdempIndex, this.productPercentSelectionMaster.sixplus)
        }
        else if(key == this.plo4pIndex[0] || key == this.plo4pIndex[1]){
            this.switchPercentage(key,this.allPercentageVal[this.plo4pIndex[0]],this.allPercentageVal[this.plo4pIndex[1]], this.plo4pIndex, this.productPercentSelectionMaster.plo4)
        }
        else if(key == this.plo5pIndex[0] || key == this.plo5pIndex[1]){
            this.switchPercentage(key,this.allPercentageVal[this.plo5pIndex[0]],this.allPercentageVal[this.plo5pIndex[1]], this.plo5pIndex, this.productPercentSelectionMaster.plo5)
        }
        
    }

    public async switchPercentage(eventInput: string, ourPercent: any, holdPercent: any, objIndex: string[], rateNumberArray: any[]) {
        if(eventInput == objIndex[0]) {
            this.allPercentageVal[objIndex[1]] = rateNumberArray[ (rateNumberArray.length - 1) - ourPercent.value]
        } 
        else {
            this.allPercentageVal[objIndex[0]] = rateNumberArray[ (rateNumberArray.length - 1) - holdPercent.value]
        }
    }

    public changeSuspend(value: boolean): void {
        this.dataAgentnew["suspend"] = value
    }

    public changeLock(value: boolean): void {
        this.dataAgentnew["lock"] = value
    }

    public async getHistoryItem(date: string) {
        this.historyLoading = false
        const id = this.$route.query.id
        const rawData = await GET_HISTORY_AGENT_BY_DATE(id.toString(), date)
        const historyData = rawData.data[0]
        this.historyData['username'] = historyData.username
        this.historyData['name'] = historyData.name
        this.historyData['lock'] = historyData.lock
        this.historyData['suspend'] = historyData.suspend
        this.historyData['email'] = historyData.information.contact.email
        this.historyData['phone'] = historyData.information.contact.phone
        this.historyData['referrer'] = historyData.information.other.referer
        this.historyData['remark'] = historyData.information.other.remark
        this.historyData['creditLimit'] = historyData.creditLimit
        this.historyData['currency'] = process.env.VUE_APP_CURRENCY_USE
        this.historyData['typeAgent'] = historyData.royaltySetting.typeAgent
        this.historyData['typeAgentAPI'] = historyData.royaltySetting.typeAgentAPI
        this.historyData['typeBusiness'] = historyData.royaltySetting.typeBusiness

        this.$modal.show('modalHistory');
        this.historyLoading = true
    }

    public convertToBoolean(myValue: any): boolean{
        if(typeof myValue === "string"){
            return (myValue === 'true')
        }
        else{
            return myValue
        }
        
    }

    public convertPercentage( value: any): number {
        if(typeof value === "object"){
            return parseInt(value.value)
        }
        else{
            return value
        }
    }

    public normalizeObj(): void {
        
        this.dataAgentnew.flodStatus = this.convertToBoolean(this.dataAgentnew.flodStatus)

        this.dataAgentnew.holdemStatus = this.convertToBoolean(this.dataAgentnew.holdemStatus) 
        this.dataAgentnew.holdemBOF = this.convertToBoolean(this.dataAgentnew.holdemBOF) 

        this.dataAgentnew.sixholdemStatus = this.convertToBoolean(this.dataAgentnew.sixholdemStatus) 
        this.dataAgentnew.plo4Status = this.convertToBoolean(this.dataAgentnew.plo4Status) 
        this.dataAgentnew.plo5Status = this.convertToBoolean(this.dataAgentnew.plo5Status)

        this.dataAgentnew.flodHoldPercentage = this.convertPercentage(this.allPercentageVal.flodHoldPercentage)  
        this.dataAgentnew.flodOurPercentage = this.convertPercentage(this.allPercentageVal.flodOurPercentage) 

        this.dataAgentnew.holdemHoldPercentage = this.convertPercentage(this.allPercentageVal.holdemHoldPercentage) 
        this.dataAgentnew.holdemOurPercentage = this.convertPercentage(this.allPercentageVal.holdemOurPercentage) 

        this.dataAgentnew.sixholdemHoldPercentage = this.convertPercentage(this.allPercentageVal.sixholdemHoldPercentage) 
        this.dataAgentnew.sixholdemOurPercentage = this.convertPercentage(this.allPercentageVal.sixholdemOurPercentage) 

        this.dataAgentnew.plo4HoldPercentage = this.convertPercentage(this.allPercentageVal.plo4HoldPercentage) 
        this.dataAgentnew.plo4OurPercentage = this.convertPercentage(this.allPercentageVal.plo4OurPercentage) 

        this.dataAgentnew.plo5HoldPercentage = this.convertPercentage(this.allPercentageVal.plo5HoldPercentage) 
        this.dataAgentnew.plo5OurPercentage = this.convertPercentage(this.allPercentageVal.plo5OurPercentage) 
        
        this.dataAgentnew?.flodLimitArr?.sort(this.compareTier)
        this.dataAgentnew?.holdemLimitArr?.sort(this.compareTier)
        this.dataAgentnew?.sixholdemLimitArr?.sort(this.compareTier)
        this.dataAgentnew?.plo4LimitArr?.sort(this.compareTier)
        this.dataAgentnew?.plo5LimitArr?.sort(this.compareTier)

    }

    public sendForm(event: any) {
        event.preventDefault();
        this.normalizeObj()
        this.sendCreateAgent()
    } 

    public async sendCreateAgent() {

        if(this.dataAgentnew.currency == ""){
            this.$swal({
                text: this.$t('MANAGEMENT.PLEASE_CHOOSE_CURRENCY').toString(),
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            return;
        }
        
        const allinOurCond = this.checkCorrectPercent(this.dataAgentnew.flodOurPercentage)
        if(allinOurCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.ALLIN_OUR_PERCENTAGE_IS_INVALID').toString())
            return;
        }

        const allinHoldCond = this.checkCorrectPercent(this.dataAgentnew.flodHoldPercentage)
        if(allinHoldCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.ALLIN_HOLD_PERCENTAGE_IS_INVALID').toString())
            return;
        }

        const holdemOurCond = this.checkCorrectPercent(this.dataAgentnew.holdemOurPercentage)
        if(holdemOurCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.HOLDEM_OUR_PERCENTAGE_IS_INVALID').toString())
            return;
        }

        const holdemHoldCond = this.checkCorrectPercent(this.dataAgentnew.holdemHoldPercentage) 
        if(holdemHoldCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.HOLDEM_HOLD_PERCENTAGE_IS_INVALID').toString())
            return;
        }
        
        const sixholdemOurCond = this.checkCorrectPercent(this.dataAgentnew.sixholdemOurPercentage) 
        if(sixholdemOurCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.SIXPLUS_HOLDEM_OUR_PERCENTAGE_IS_INVALID').toString())
            return;
        }

        const sixholdemHoldCond = this.checkCorrectPercent(this.dataAgentnew.sixholdemHoldPercentage) 
        if(sixholdemHoldCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.SIXPLUS_HOLDEM_HOLD_PERCENTAGE_IS_INVALID').toString())
            return;
        }
        
        const plo4OurCond = this.checkCorrectPercent(this.dataAgentnew.plo4OurPercentage)
        if(plo4OurCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.PLO4_OUR_PERCENTAGE_IS_INVALID').toString())
            return;
        }
        
        const plo4HoldCond = this.checkCorrectPercent(this.dataAgentnew.plo4HoldPercentage) 
        if(plo4HoldCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.PLO4_HOLD_PERCENTAGE_IS_INVALID').toString())
            return;
        }

        const plo5OurCond = this.checkCorrectPercent(this.dataAgentnew.plo5OurPercentage)
        if(plo5OurCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.PLO5_OUR_PERCENTAGE_IS_INVALID').toString())
            return;
        }

        const plo5HoldCond = this.checkCorrectPercent(this.dataAgentnew.plo5HoldPercentage) 
        if(plo5HoldCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.PLO5_HOLD_PERCENTAGE_IS_INVALID').toString())
            return;
        }

        const result = await this.$swal({
            title: `${this.$t('MANAGEMENT.WANT_EDIT_DATA').toString()}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#CFA137',
            confirmButtonText: `<span style='color: white;'>${this.$t('CONFIRM').toString()}</span>`,
            cancelButtonColor: '#FF0000',
            cancelButtonText: `<span style='color: white;'>${this.$t('CANCEL').toString()}</span>`,
        })

        if (!result.isConfirmed) {
            return
        }

        const resultEditAgent = await EDIT_AGENT_NEW(this.dataAgentnew)
        if (!resultEditAgent.success) {
            this.$swal({
                text: resultEditAgent.error.message,
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            return
        }

        if (this.role == 'Company') {
            this.$swal({
                text: `${this.$t('MANAGEMENT.EDIT_SHAREHOLDER_SUCCESS').toString()}`,
                icon: "success",
                timer: 3000,
                showConfirmButton: false,
                timerProgressBar: true,
                onClose: () => {
                    window.location.replace('/agent/agent-member-list');
                }
            });
        } else {
            this.$swal({
                text: `${this.$t('MANAGEMENT.EDIT_AGENT_SUCCESS').toString()}`,
                icon: "success",
                timer: 3000,
                showConfirmButton: false,
                timerProgressBar: true,
                onClose: () => {
                    window.location.replace('/agent/agent-member-list');
                }
            });
        }

    }

    public async sendNewPassword(): Promise<void> {
        
        if (this.dataAgentnew.password.length < 8) {
            this.$swal({
                text: this.$t('MANAGEMENT.PASSWORD_CHECK').toString(),
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            return;
        }

        const loader = this.$loading.show(this.pageSpinStyle);

        const data = {
            username: this.dataAgentnew.username,
            password: this.dataAgentnew.password
        }

        const resultEditPassword = await CHANGE_CHILD_AGENT_PASSWORD(data)

        loader.hide()
        if (!resultEditPassword.success) {
            this.$swal({
                text: resultEditPassword.error.message,
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            return
        } else {
            this.hideModal("changePasswordModal")
            this.$swal({
                text: `${this.$t('MANAGEMENT.CHANGE_PASSWORD_SUCCESS').toString()}`,
                icon: "success",
                timer: 3000,
                showConfirmButton: false,
                timerProgressBar: true,
            });
        }
        
    }
    
    public checkCorrectPercent(percent: any){
        return typeof percent != "number" || percent > 100 || percent < 0
    }

    public gameTypePercentSwal(message: string){
        this.$swal({
            text: message,
            icon: "error",
            confirmButtonColor: '#CFA137',
            confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
        });
    }
    
    public openModal(modalName: string){
        switch (modalName) {
            case 'changePasswordModal':
                this.$modal.show('changePasswordModal');
                this.dataAgentnew.password = ''
                break;
        }
    }
    

    public hideModal(modalName: string) {
        switch (modalName) {
            case 'modalHistory':
                this.$modal.hide('modalHistory');
                break;
            case 'changePasswordModal':
                this.$modal.hide('changePasswordModal');
                break;
        }

    }

    compareTier(a: string, b: string) {
        const aTier = parseInt(a.split('/')[0])
        const bTier = parseInt(b.split('/')[0])
        return aTier - bTier
    }

}
export default EditAgent