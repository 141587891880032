var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayShow
    ? _c(
        "div",
        [
          _c("div", [
            _vm.role == "Company"
              ? _c("h1", [
                  _vm._v(_vm._s(_vm.$t("MANAGEMENT.EDIT_SHAREHOLDER")) + " ")
                ])
              : _c("h1", [_vm._v(_vm._s(_vm.$t("MANAGEMENT.EDIT_AGENT")))]),
            _c("div", { staticClass: "section-group" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-8" }, [
                  _c(
                    "form",
                    {
                      attrs: { lass: "forms-sample", id: "form-agent" },
                      on: {
                        submit: function($event) {
                          return _vm.sendForm($event)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "mb-4" }, [
                        _c("div", { staticStyle: { display: "inline-flex" } }, [
                          _vm.role == "Company"
                            ? _c("h4", { staticClass: "bold" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("MANAGEMENT.SHAREHOLDER_INFO"))
                                )
                              ])
                            : _c("h4", { staticClass: "bold" }, [
                                _vm._v(_vm._s(_vm.$t("MANAGEMENT.AGENT_INFO")))
                              ])
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Username" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.USERNAME")) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "required" }, [
                                    _vm._v("★")
                                  ])
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.dataAgentnew.username,
                                      expression: "dataAgentnew.username"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "Username",
                                    name: "Username",
                                    placeholder: _vm.$t("MANAGEMENT.USERNAME"),
                                    disabled: true,
                                    autocomplete: "off",
                                    required: ""
                                  },
                                  domProps: {
                                    value: _vm.dataAgentnew.username
                                  },
                                  on: {
                                    keypress: function($event) {
                                      return _vm.clsAlphaNoOnly($event)
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.dataAgentnew,
                                        "username",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _c(
                                  "small",
                                  { staticClass: "text-muted form-text" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("MANAGEMENT.TEXT_NUMBER_ONLY")
                                      )
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Name" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.NAME")) +
                                      " "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.dataAgentnew.name,
                                      expression: "dataAgentnew.name"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "Name",
                                    name: "Name",
                                    placeholder: _vm.$t("MANAGEMENT.NAME")
                                  },
                                  domProps: { value: _vm.dataAgentnew.name },
                                  on: {
                                    keypress: function($event) {
                                      return _vm.clsAlphaNoOnly($event)
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.dataAgentnew,
                                        "name",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _c(
                                  "small",
                                  { staticClass: "text-muted form-text" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("MANAGEMENT.TEXT_NUMBER_ONLY")
                                      )
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Password" }
                                },
                                [_vm._v(_vm._s(_vm.$t("MANAGEMENT.PASSWORD")))]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-yellow ripple-parent mr-2",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openModal(
                                          "changePasswordModal"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("AGENT.BUTTON.EDIT_PASSWORD")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "mb-4" }, [
                        _c("h4", { staticClass: "bold" }, [
                          _vm._v(_vm._s(_vm.$t("MANAGEMENT.CONTACT_INFO")))
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Email" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.EMAIL")) +
                                      " "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.dataAgentnew.email,
                                      expression: "dataAgentnew.email"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "email",
                                    id: "Email",
                                    name: "Email",
                                    placeholder: _vm.$t("MANAGEMENT.EMAIL")
                                  },
                                  domProps: { value: _vm.dataAgentnew.email },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.dataAgentnew,
                                        "email",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Phone" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.PHONE")) +
                                      " "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.dataAgentnew.phone,
                                      expression: "dataAgentnew.phone"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "Phone",
                                    name: "Phone",
                                    placeholder: _vm.$t("MANAGEMENT.PHONE")
                                  },
                                  domProps: { value: _vm.dataAgentnew.phone },
                                  on: {
                                    keydown: function($event) {
                                      return _vm.onlyNumber($event)
                                    },
                                    keypress: function($event) {
                                      return _vm.onlyNumber($event)
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.dataAgentnew,
                                        "phone",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _c(
                                  "small",
                                  { staticClass: "text-muted form-text" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("MANAGEMENT.NUMBER_ONLY"))
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "mb-4" }, [
                        _c("h4", { staticClass: "bold" }, [
                          _vm._v(_vm._s(_vm.$t("MANAGEMENT.OTHER_INFO")))
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Referrer" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.REFERRER")) +
                                      " "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.dataAgentnew.referrer,
                                      expression: "dataAgentnew.referrer"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "Referrer",
                                    placeholder: _vm.$t("MANAGEMENT.REFERRER")
                                  },
                                  domProps: {
                                    value: _vm.dataAgentnew.referrer
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.dataAgentnew,
                                        "referrer",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Remark" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.REMARK")) +
                                      " "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.dataAgentnew.remark,
                                      expression: "dataAgentnew.remark"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "Remark",
                                    placeholder: _vm.$t("MANAGEMENT.REMARK")
                                  },
                                  domProps: { value: _vm.dataAgentnew.remark },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.dataAgentnew,
                                        "remark",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "mb-4" }, [
                        _c("h4", { staticClass: "bold" }, [
                          _vm._v(_vm._s(_vm.$t("MANAGEMENT.STATUS")))
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Suspend" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("MANAGEMENT.BUTTON.SUSPEND")
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "btn-group btn-group-toggle d-flex",
                                    attrs: {
                                      role: "group",
                                      "aria-label": "Basic example"
                                    }
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        class: {
                                          active: _vm.dataAgentnew.suspend
                                        },
                                        attrs: {
                                          id: "selectSuspend",
                                          type: "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.changeSuspend(true)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("YES")) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        class: {
                                          active: !_vm.dataAgentnew.suspend
                                        },
                                        attrs: {
                                          id: "selectNoSuspend",
                                          type: "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.changeSuspend(false)
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(_vm.$t("NO")) + " ")]
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-4 col-form-label bold",
                                  attrs: { for: "Lock" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("MANAGEMENT.BUTTON.LOCK")) +
                                      " "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "col-8" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "btn-group btn-group-toggle d-flex",
                                    attrs: {
                                      role: "group",
                                      "aria-label": "Basic example"
                                    }
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        class: {
                                          active: _vm.dataAgentnew.lock
                                        },
                                        attrs: {
                                          id: "selectLOCK",
                                          type: "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.changeLock(true)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("YES")) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        class: {
                                          active: !_vm.dataAgentnew.lock
                                        },
                                        attrs: {
                                          id: "selectNoLOCK",
                                          type: "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.changeLock(false)
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(_vm.$t("NO")) + " ")]
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("div", { staticClass: "mb-4" }, [
                            _c("h4", { staticClass: "bold" }, [
                              _vm._v(
                                _vm._s(_vm.$t("MANAGEMENT.SETTING_CREDIT"))
                              )
                            ]),
                            _vm._m(0)
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-12" }, [
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  { staticClass: "col-4 col-form-label bold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("MANAGEMENT.CURRENCY")) +
                                        " "
                                    ),
                                    _c("span", { staticClass: "required" }, [
                                      _vm._v("★")
                                    ])
                                  ]
                                ),
                                _c("div", { staticClass: "col-8" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.dataAgentnew.currency,
                                        expression: "dataAgentnew.currency"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "currency",
                                      name: "currency",
                                      placeholder: _vm.$t(
                                        "MANAGEMENT.CURRENCY"
                                      ),
                                      disabled: true
                                    },
                                    domProps: {
                                      value: _vm.dataAgentnew.currency
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.dataAgentnew,
                                          "currency",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-12" }, [
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  { staticClass: "col-4 col-form-label bold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("MANAGEMENT.CREDIT")) +
                                        " "
                                    ),
                                    _c("span", { staticClass: "required" }, [
                                      _vm._v("★")
                                    ])
                                  ]
                                ),
                                _c("div", { staticClass: "col-8" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.dataAgentnew.creditLimit,
                                        expression: "dataAgentnew.creditLimit"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "creditLimit",
                                      name: "creditLimit",
                                      placeholder: _vm.$t("MANAGEMENT.CREDIT"),
                                      disabled: true
                                    },
                                    domProps: {
                                      value: _vm.dataAgentnew.creditLimit
                                    },
                                    on: {
                                      keydown: function($event) {
                                        return _vm.onlyNumber($event)
                                      },
                                      keypress: function($event) {
                                        return _vm.onlyNumber($event)
                                      },
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.dataAgentnew,
                                          "creditLimit",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ])
                          ]),
                          _vm.role == "Company"
                            ? _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-12" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "col-4 col-form-label bold"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.POSITION_TYPE")
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "required" },
                                          [_vm._v("★")]
                                        )
                                      ]
                                    ),
                                    _c("div", { staticClass: "col-8" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.dataAgentnew.typeBusiness,
                                            expression:
                                              "dataAgentnew.typeBusiness"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          id: "typeBusiness",
                                          name: "typeBusiness",
                                          placeholder: _vm.$t(
                                            "MANAGEMENT.POSITION_TYPE"
                                          ),
                                          disabled: true
                                        },
                                        domProps: {
                                          value: _vm.dataAgentnew.typeBusiness
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.dataAgentnew,
                                              "typeBusiness",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  ])
                                ])
                              ])
                            : _vm._e(),
                          _vm.role == "Shareholder" ||
                          _vm.role == "Master Agent"
                            ? _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-12" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "col-4 col-form-label bold"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.POSITION_TYPE")
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "required" },
                                          [_vm._v("★")]
                                        )
                                      ]
                                    ),
                                    _c("div", { staticClass: "col-8" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.dataAgentnew.typeAgent,
                                            expression: "dataAgentnew.typeAgent"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          id: "typeAgent",
                                          name: "typeAgent",
                                          placeholder: _vm.$t(
                                            "MANAGEMENT.POSITION_TYPE"
                                          ),
                                          disabled: true
                                        },
                                        domProps: {
                                          value: _vm.dataAgentnew.typeAgent
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.dataAgentnew,
                                              "typeAgent",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  ])
                                ])
                              ])
                            : _vm._e(),
                          (_vm.role == "Shareholder" ||
                            _vm.role == "Master Agent") &&
                          _vm.dataAgentnew.typeAgent == "API"
                            ? _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-12" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "col-4 col-form-label bold"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.BET_TYPE")
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "required" },
                                          [_vm._v("★")]
                                        )
                                      ]
                                    ),
                                    _c("div", { staticClass: "col-8" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.dataAgentnew.typeAgentAPI,
                                            expression:
                                              "dataAgentnew.typeAgentAPI"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          id: "typeAgentAPI",
                                          name: "typeAgentAPI",
                                          placeholder: _vm.$t(
                                            "MANAGEMENT.BET_TYPE"
                                          ),
                                          disabled: true
                                        },
                                        domProps: {
                                          value: _vm.dataAgentnew.typeAgentAPI
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.dataAgentnew,
                                              "typeAgentAPI",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  ])
                                ])
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _c("div", { staticClass: "tab-section card col-md-12" }, [
                        _c(
                          "div",
                          { staticClass: "tab-control-panel card-header" },
                          [
                            _c(
                              "ul",
                              {
                                staticClass: "nav nav-tabs",
                                attrs: { role: "tablist" }
                              },
                              [
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link active",
                                      attrs: {
                                        href: "#tl-md1",
                                        id: "",
                                        "data-toggle": "tab",
                                        role: "tab",
                                        "aria-controls": "tl-tab-h1",
                                        "aria-selected": "true"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("GAME_TYPE.ALLIN_OR_FOLD")
                                        )
                                      )
                                    ]
                                  )
                                ]),
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        href: "#tl-md2",
                                        id: "",
                                        "data-toggle": "tab",
                                        role: "tab",
                                        "aria-controls": "ll-tab-md",
                                        "aria-selected": "false"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("GAME_TYPE.HOLDEM")))]
                                  )
                                ]),
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        href: "#tl-md3",
                                        id: "",
                                        "data-toggle": "tab",
                                        role: "tab",
                                        "aria-controls": "",
                                        "aria-selected": "false"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("GAME_TYPE.SIX_PLUS_HOLDEM")
                                        )
                                      )
                                    ]
                                  )
                                ]),
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        href: "#tl-md4",
                                        id: "",
                                        "data-toggle": "tab",
                                        role: "tab",
                                        "aria-controls": "",
                                        "aria-selected": "false"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("GAME_TYPE.PLO4")))]
                                  )
                                ]),
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        href: "#tl-md5",
                                        id: "",
                                        "data-toggle": "tab",
                                        role: "tab",
                                        "aria-controls": "",
                                        "aria-selected": "false"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("GAME_TYPE.PLO5")))]
                                  )
                                ])
                              ]
                            )
                          ]
                        ),
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "tab-content" }, [
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade show active",
                                attrs: {
                                  id: "tl-md1",
                                  role: "tabpanel",
                                  "aria-labelledby": ""
                                }
                              },
                              [
                                _c("div", { staticClass: "row mb-0" }, [
                                  _c("div", { staticClass: "col-7 pr-0" }, [
                                    _vm._m(1),
                                    _c("div", { staticClass: "row" }),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-4 col-form-label bold"
                                              },
                                              [_vm._v(" Status ")]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-8" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataAgentnew
                                                          .flodStatus,
                                                      expression:
                                                        "dataAgentnew.flodStatus"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "statusflod1",
                                                    name: "statusflod",
                                                    value: "true"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataAgentnew
                                                        .flodStatus,
                                                      "true"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataAgentnew,
                                                        "flodStatus",
                                                        "true"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "statusflod1"
                                                    }
                                                  },
                                                  [_vm._v(" Enable ")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataAgentnew
                                                          .flodStatus,
                                                      expression:
                                                        "dataAgentnew.flodStatus"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "statusflod2",
                                                    name: "statusflod",
                                                    value: "false"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataAgentnew
                                                        .flodStatus,
                                                      "false"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataAgentnew,
                                                        "flodStatus",
                                                        "false"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "statusflod2"
                                                    }
                                                  },
                                                  [_vm._v(" Disable ")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.HOLD_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                ),
                                                _vm._v(" : ")
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .allinorfold,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    )
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.changePercentage(
                                                        "flodOurPercentage"
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .flodOurPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "flodOurPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.flodOurPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.GIVE_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                ),
                                                _vm._v(" : ")
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .allinorfold,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    )
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.changePercentage(
                                                        "flodHoldPercentage"
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .flodHoldPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "flodHoldPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.flodHoldPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-5" },
                                    [
                                      _c("div", { staticClass: "mb-4" }, [
                                        _c("h4", { staticClass: "bold" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.LIMIT_SETTING")
                                            )
                                          )
                                        ]),
                                        _c("div", { staticClass: "row" })
                                      ]),
                                      _vm._l(_vm.flod_limit_list, function(
                                        t,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          { key: index, staticClass: "row" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-sm-12" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group row"
                                                  },
                                                  [
                                                    _c("label", {
                                                      staticClass:
                                                        "col-1 col-form-label bold"
                                                    }),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-11" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.dataAgentnew
                                                                  .flodLimitArr,
                                                              expression:
                                                                "dataAgentnew.flodLimitArr"
                                                            }
                                                          ],
                                                          staticClass: "chk",
                                                          attrs: {
                                                            type: "checkbox",
                                                            id:
                                                              "allin_checkbox_" +
                                                              index,
                                                            disabled:
                                                              _vm
                                                                .parentRoyaltySetting
                                                                .allinorfold ==
                                                                undefined ||
                                                              _vm.parentRoyaltySetting.allinorfold.limit.indexOf(
                                                                t.tierValue
                                                              ) < 0
                                                          },
                                                          domProps: {
                                                            value: t.tierValue,
                                                            checked: Array.isArray(
                                                              _vm.dataAgentnew
                                                                .flodLimitArr
                                                            )
                                                              ? _vm._i(
                                                                  _vm
                                                                    .dataAgentnew
                                                                    .flodLimitArr,
                                                                  t.tierValue
                                                                ) > -1
                                                              : _vm.dataAgentnew
                                                                  .flodLimitArr
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm
                                                                    .dataAgentnew
                                                                    .flodLimitArr,
                                                                $$el =
                                                                  $event.target,
                                                                $$c = $$el.checked
                                                                  ? true
                                                                  : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v =
                                                                    t.tierValue,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.dataAgentnew,
                                                                      "flodLimitArr",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.dataAgentnew,
                                                                      "flodLimitArr",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.dataAgentnew,
                                                                  "flodLimitArr",
                                                                  $$c
                                                                )
                                                              }
                                                            }
                                                          }
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "form-check-label bold",
                                                            attrs: {
                                                              for:
                                                                "allin_checkbox_" +
                                                                index
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(t.name) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade",
                                attrs: {
                                  id: "tl-md2",
                                  role: "tabpanel",
                                  "aria-labelledby": ""
                                }
                              },
                              [
                                _c("div", { staticClass: "row mb-0" }, [
                                  _c("div", { staticClass: "col-7 pr-0" }, [
                                    _vm._m(2),
                                    _c("div", { staticClass: "row" }),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-4 col-form-label bold"
                                              },
                                              [_vm._v(" Status ")]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-8" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataAgentnew
                                                          .holdemStatus,
                                                      expression:
                                                        "dataAgentnew.holdemStatus"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "statudholdem1",
                                                    name: "statusholdem",
                                                    value: "true"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataAgentnew
                                                        .holdemStatus,
                                                      "true"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataAgentnew,
                                                        "holdemStatus",
                                                        "true"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "statudholdem1"
                                                    }
                                                  },
                                                  [_vm._v(" Enable ")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataAgentnew
                                                          .holdemStatus,
                                                      expression:
                                                        "dataAgentnew.holdemStatus"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "statudholdem2",
                                                    name: "statusholdem",
                                                    value: "false"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataAgentnew
                                                        .holdemStatus,
                                                      "false"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataAgentnew,
                                                        "holdemStatus",
                                                        "false"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "statudholdem2"
                                                    }
                                                  },
                                                  [_vm._v(" Disable ")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.HOLD_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .holdem,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    )
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.changePercentage(
                                                        "holdemOurPercentage"
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .holdemOurPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "holdemOurPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.holdemOurPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.GIVE_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .holdem,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    )
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.changePercentage(
                                                        "holdemHoldPercentage"
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .holdemHoldPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "holdemHoldPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.holdemHoldPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-4 col-form-label bold"
                                              },
                                              [_vm._v(" Bet on Flop ")]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-8" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataAgentnew
                                                          .holdemBOF,
                                                      expression:
                                                        "dataAgentnew.holdemBOF"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "status_holdem_bof1",
                                                    name: "status_holdem_bof",
                                                    value: "true",
                                                    disabled:
                                                      _vm.disabledInputMiniGame
                                                        .holdemBOF
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataAgentnew
                                                        .holdemBOF,
                                                      "true"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataAgentnew,
                                                        "holdemBOF",
                                                        "true"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    style: [
                                                      _vm.disabledInputMiniGame
                                                        .holdemBOF
                                                        ? {
                                                            cursor:
                                                              "not-allowed"
                                                          }
                                                        : ""
                                                    ],
                                                    attrs: {
                                                      for: "status_holdem_bof1"
                                                    }
                                                  },
                                                  [_vm._v(" Enable ")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataAgentnew
                                                          .holdemBOF,
                                                      expression:
                                                        "dataAgentnew.holdemBOF"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "status_holdem_bof2",
                                                    name: "status_holdem_bof",
                                                    value: "false"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataAgentnew
                                                        .holdemBOF,
                                                      "false"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataAgentnew,
                                                        "holdemBOF",
                                                        "false"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    style: [
                                                      _vm.disabledInputMiniGame
                                                        .holdemBOF
                                                        ? {
                                                            cursor:
                                                              "not-allowed"
                                                          }
                                                        : ""
                                                    ],
                                                    attrs: {
                                                      for: "status_holdem_bof2"
                                                    }
                                                  },
                                                  [_vm._v(" Disable ")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-5" },
                                    [
                                      _c("div", { staticClass: "mb-4" }, [
                                        _c("h4", { staticClass: "bold" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.LIMIT_SETTING")
                                            )
                                          )
                                        ]),
                                        _c("div", { staticClass: "row" })
                                      ]),
                                      _vm._l(_vm.holdem_limit_list, function(
                                        t,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          { key: index, staticClass: "row" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-sm-12" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group row"
                                                  },
                                                  [
                                                    _c("label", {
                                                      staticClass:
                                                        "col-1 col-form-label bold"
                                                    }),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-11" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.dataAgentnew
                                                                  .holdemLimitArr,
                                                              expression:
                                                                "dataAgentnew.holdemLimitArr"
                                                            }
                                                          ],
                                                          staticClass: "chk",
                                                          attrs: {
                                                            type: "checkbox",
                                                            id:
                                                              "holdem_checkbox_" +
                                                              index,
                                                            disabled:
                                                              _vm
                                                                .parentRoyaltySetting
                                                                .holdem ==
                                                                undefined ||
                                                              _vm.parentRoyaltySetting.holdem.limit.indexOf(
                                                                t.tierValue
                                                              ) < 0
                                                          },
                                                          domProps: {
                                                            value: t.tierValue,
                                                            checked: Array.isArray(
                                                              _vm.dataAgentnew
                                                                .holdemLimitArr
                                                            )
                                                              ? _vm._i(
                                                                  _vm
                                                                    .dataAgentnew
                                                                    .holdemLimitArr,
                                                                  t.tierValue
                                                                ) > -1
                                                              : _vm.dataAgentnew
                                                                  .holdemLimitArr
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm
                                                                    .dataAgentnew
                                                                    .holdemLimitArr,
                                                                $$el =
                                                                  $event.target,
                                                                $$c = $$el.checked
                                                                  ? true
                                                                  : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v =
                                                                    t.tierValue,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.dataAgentnew,
                                                                      "holdemLimitArr",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.dataAgentnew,
                                                                      "holdemLimitArr",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.dataAgentnew,
                                                                  "holdemLimitArr",
                                                                  $$c
                                                                )
                                                              }
                                                            }
                                                          }
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "form-check-label bold",
                                                            attrs: {
                                                              for:
                                                                "holdem_checkbox_" +
                                                                index
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(t.name) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade",
                                attrs: {
                                  id: "tl-md3",
                                  role: "tabpanel",
                                  "aria-labelledby": ""
                                }
                              },
                              [
                                _c("div", { staticClass: "row mb-0" }, [
                                  _c("div", { staticClass: "col-7 pr-0" }, [
                                    _vm._m(3),
                                    _c("div", { staticClass: "row" }),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-4 col-form-label bold"
                                              },
                                              [_vm._v(" Status ")]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-8" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataAgentnew
                                                          .sixholdemStatus,
                                                      expression:
                                                        "dataAgentnew.sixholdemStatus"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "sixholdemstatus1",
                                                    name: "sixholdemstatus",
                                                    value: "true"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataAgentnew
                                                        .sixholdemStatus,
                                                      "true"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataAgentnew,
                                                        "sixholdemStatus",
                                                        "true"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "sixholdemstatus1"
                                                    }
                                                  },
                                                  [_vm._v(" Enable ")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataAgentnew
                                                          .sixholdemStatus,
                                                      expression:
                                                        "dataAgentnew.sixholdemStatus"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "sixholdemstatus2",
                                                    name: "sixholdemstatus",
                                                    value: "false"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataAgentnew
                                                        .sixholdemStatus,
                                                      "false"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataAgentnew,
                                                        "sixholdemStatus",
                                                        "false"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "sixholdemstatus2"
                                                    }
                                                  },
                                                  [_vm._v(" Disable ")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.HOLD_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .sixplus,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    )
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.changePercentage(
                                                        "sixholdemOurPercentage"
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .sixholdemOurPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "sixholdemOurPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.sixholdemOurPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.GIVE_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .sixplus,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    )
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.changePercentage(
                                                        "sixholdemHoldPercentage"
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .sixholdemHoldPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "sixholdemHoldPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.sixholdemHoldPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-5" },
                                    [
                                      _c("div", { staticClass: "mb-4" }, [
                                        _c("h4", { staticClass: "bold" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.LIMIT_SETTING")
                                            )
                                          )
                                        ]),
                                        _c("div", { staticClass: "row" })
                                      ]),
                                      _vm._l(
                                        _vm.sixplus_holdemlimit_list,
                                        function(t, index) {
                                          return _c(
                                            "div",
                                            { key: index, staticClass: "row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-12" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group row"
                                                    },
                                                    [
                                                      _c("label", {
                                                        staticClass:
                                                          "col-1 col-form-label bold"
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-11"
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .dataAgentnew
                                                                    .sixholdemLimitArr,
                                                                expression:
                                                                  "dataAgentnew.sixholdemLimitArr"
                                                              }
                                                            ],
                                                            staticClass: "chk",
                                                            attrs: {
                                                              type: "checkbox",
                                                              id:
                                                                "sixholdem_checkbox_" +
                                                                index,
                                                              disabled:
                                                                _vm
                                                                  .parentRoyaltySetting
                                                                  .sixplus ==
                                                                  undefined ||
                                                                _vm.parentRoyaltySetting.sixplus.limit.indexOf(
                                                                  t.tierValue
                                                                ) < 0
                                                            },
                                                            domProps: {
                                                              value:
                                                                t.tierValue,
                                                              checked: Array.isArray(
                                                                _vm.dataAgentnew
                                                                  .sixholdemLimitArr
                                                              )
                                                                ? _vm._i(
                                                                    _vm
                                                                      .dataAgentnew
                                                                      .sixholdemLimitArr,
                                                                    t.tierValue
                                                                  ) > -1
                                                                : _vm
                                                                    .dataAgentnew
                                                                    .sixholdemLimitArr
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    _vm
                                                                      .dataAgentnew
                                                                      .sixholdemLimitArr,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c = $$el.checked
                                                                    ? true
                                                                    : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      t.tierValue,
                                                                    $$i = _vm._i(
                                                                      $$a,
                                                                      $$v
                                                                    )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        _vm.dataAgentnew,
                                                                        "sixholdemLimitArr",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        _vm.dataAgentnew,
                                                                        "sixholdemLimitArr",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    _vm.dataAgentnew,
                                                                    "sixholdemLimitArr",
                                                                    $$c
                                                                  )
                                                                }
                                                              }
                                                            }
                                                          }),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "form-check-label bold",
                                                              attrs: {
                                                                for:
                                                                  "sixholdem_checkbox_" +
                                                                  index
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    t.name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade",
                                attrs: {
                                  id: "tl-md4",
                                  role: "tabpanel",
                                  "aria-labelledby": ""
                                }
                              },
                              [
                                _c("div", { staticClass: "row mb-0" }, [
                                  _c("div", { staticClass: "col-7 pr-0" }, [
                                    _vm._m(4),
                                    _c("div", { staticClass: "row" }),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-4 col-form-label bold"
                                              },
                                              [_vm._v(" Status ")]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-8" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataAgentnew
                                                          .plo4Status,
                                                      expression:
                                                        "dataAgentnew.plo4Status"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "plo4status1",
                                                    name: "plo4status",
                                                    value: "true"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataAgentnew
                                                        .plo4Status,
                                                      "true"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataAgentnew,
                                                        "plo4Status",
                                                        "true"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "plo4status1"
                                                    }
                                                  },
                                                  [_vm._v(" Enable ")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataAgentnew
                                                          .plo4Status,
                                                      expression:
                                                        "dataAgentnew.plo4Status"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "plo4status2",
                                                    name: "plo4status",
                                                    value: "false"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataAgentnew
                                                        .plo4Status,
                                                      "false"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataAgentnew,
                                                        "plo4Status",
                                                        "false"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "plo4status2"
                                                    }
                                                  },
                                                  [_vm._v(" Disable ")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.HOLD_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .plo4,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    )
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.changePercentage(
                                                        "plo4OurPercentage"
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .plo4OurPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "plo4OurPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.plo4OurPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.GIVE_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "",
                                                    name: "",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .plo4,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    )
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.changePercentage(
                                                        "plo4HoldPercentage"
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .plo4HoldPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "plo4HoldPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.plo4HoldPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-5" },
                                    [
                                      _c("div", { staticClass: "mb-4" }, [
                                        _c("h4", { staticClass: "bold" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.LIMIT_SETTING")
                                            )
                                          )
                                        ]),
                                        _c("div", { staticClass: "row" })
                                      ]),
                                      _vm._l(_vm.plo4_limit_list, function(
                                        t,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          { key: index, staticClass: "row" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-sm-12" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group row"
                                                  },
                                                  [
                                                    _c("label", {
                                                      staticClass:
                                                        "col-1 col-form-label bold"
                                                    }),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-11" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.dataAgentnew
                                                                  .plo4LimitArr,
                                                              expression:
                                                                "dataAgentnew.plo4LimitArr"
                                                            }
                                                          ],
                                                          staticClass: "chk",
                                                          attrs: {
                                                            type: "checkbox",
                                                            id:
                                                              "plo4_checkbox_" +
                                                              index,
                                                            disabled:
                                                              _vm
                                                                .parentRoyaltySetting
                                                                .plo4 ==
                                                                undefined ||
                                                              _vm.parentRoyaltySetting.plo4.limit.indexOf(
                                                                t.tierValue
                                                              ) < 0
                                                          },
                                                          domProps: {
                                                            value: t.tierValue,
                                                            checked: Array.isArray(
                                                              _vm.dataAgentnew
                                                                .plo4LimitArr
                                                            )
                                                              ? _vm._i(
                                                                  _vm
                                                                    .dataAgentnew
                                                                    .plo4LimitArr,
                                                                  t.tierValue
                                                                ) > -1
                                                              : _vm.dataAgentnew
                                                                  .plo4LimitArr
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm
                                                                    .dataAgentnew
                                                                    .plo4LimitArr,
                                                                $$el =
                                                                  $event.target,
                                                                $$c = $$el.checked
                                                                  ? true
                                                                  : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v =
                                                                    t.tierValue,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.dataAgentnew,
                                                                      "plo4LimitArr",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.dataAgentnew,
                                                                      "plo4LimitArr",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.dataAgentnew,
                                                                  "plo4LimitArr",
                                                                  $$c
                                                                )
                                                              }
                                                            }
                                                          }
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "form-check-label bold",
                                                            attrs: {
                                                              for:
                                                                "plo4_checkbox_" +
                                                                index
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(t.name) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade",
                                attrs: {
                                  id: "tl-md5",
                                  role: "tabpanel",
                                  "aria-labelledby": ""
                                }
                              },
                              [
                                _c("div", { staticClass: "row mb-0" }, [
                                  _c("div", { staticClass: "col-7 pr-0" }, [
                                    _vm._m(5),
                                    _c("div", { staticClass: "row" }),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-4 col-form-label bold"
                                              },
                                              [_vm._v(" Status ")]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-8" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataAgentnew
                                                          .plo5Status,
                                                      expression:
                                                        "dataAgentnew.plo5Status"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "plo5status1",
                                                    name: "plo5status",
                                                    value: "true"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataAgentnew
                                                        .plo5Status,
                                                      "true"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataAgentnew,
                                                        "plo5Status",
                                                        "true"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "plo5status1"
                                                    }
                                                  },
                                                  [_vm._v(" Enable ")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.dataAgentnew
                                                          .plo5Status,
                                                      expression:
                                                        "dataAgentnew.plo5Status"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: "plo5status2",
                                                    name: "plo5status",
                                                    value: "false"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.dataAgentnew
                                                        .plo5Status,
                                                      "false"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.dataAgentnew,
                                                        "plo5Status",
                                                        "false"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: "plo5status2"
                                                    }
                                                  },
                                                  [_vm._v(" Disable ")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.HOLD_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .plo5,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    )
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.changePercentage(
                                                        "plo5OurPercentage"
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .plo5OurPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "plo5OurPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.plo5OurPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "col-6 col-form-label bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MANAGEMENT.GIVE_PERCENTAGE"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("★")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    required: "",
                                                    id: "myPercentage",
                                                    name: "myPercentage",
                                                    clearable: false,
                                                    options:
                                                      _vm
                                                        .productPercentSelectionMaster
                                                        .plo5,
                                                    placeholder: _vm.$t(
                                                      "MANAGEMENT.ENTER_PERCENTAGE"
                                                    )
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.changePercentage(
                                                        "plo5HoldPercentage"
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.allPercentageVal
                                                        .plo5HoldPercentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.allPercentageVal,
                                                        "plo5HoldPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "allPercentageVal.plo5HoldPercentage"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-5" },
                                    [
                                      _c("div", { staticClass: "mb-4" }, [
                                        _c("h4", { staticClass: "bold" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.LIMIT_SETTING")
                                            )
                                          )
                                        ]),
                                        _c("div", { staticClass: "row" })
                                      ]),
                                      _vm._l(_vm.plo5_limit_list, function(
                                        t,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          { key: index, staticClass: "row" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-sm-12" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group row"
                                                  },
                                                  [
                                                    _c("label", {
                                                      staticClass:
                                                        "col-1 col-form-label bold"
                                                    }),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-11" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.dataAgentnew
                                                                  .plo5LimitArr,
                                                              expression:
                                                                "dataAgentnew.plo5LimitArr"
                                                            }
                                                          ],
                                                          staticClass: "chk",
                                                          attrs: {
                                                            type: "checkbox",
                                                            id:
                                                              "plo5_checkbox_" +
                                                              index,
                                                            disabled:
                                                              _vm
                                                                .parentRoyaltySetting
                                                                .plo5 ==
                                                                undefined ||
                                                              _vm.parentRoyaltySetting.plo5.limit.indexOf(
                                                                t.tierValue
                                                              ) < 0
                                                          },
                                                          domProps: {
                                                            value: t.tierValue,
                                                            checked: Array.isArray(
                                                              _vm.dataAgentnew
                                                                .plo5LimitArr
                                                            )
                                                              ? _vm._i(
                                                                  _vm
                                                                    .dataAgentnew
                                                                    .plo5LimitArr,
                                                                  t.tierValue
                                                                ) > -1
                                                              : _vm.dataAgentnew
                                                                  .plo5LimitArr
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm
                                                                    .dataAgentnew
                                                                    .plo5LimitArr,
                                                                $$el =
                                                                  $event.target,
                                                                $$c = $$el.checked
                                                                  ? true
                                                                  : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v =
                                                                    t.tierValue,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.dataAgentnew,
                                                                      "plo5LimitArr",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.dataAgentnew,
                                                                      "plo5LimitArr",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.dataAgentnew,
                                                                  "plo5LimitArr",
                                                                  $$c
                                                                )
                                                              }
                                                            }
                                                          }
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "form-check-label bold",
                                                            attrs: {
                                                              for:
                                                                "plo5_checkbox_" +
                                                                index
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(t.name) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      ]),
                      _c("div", { staticStyle: { height: "10px" } }),
                      _c(
                        "div",
                        {
                          staticClass: "mb-4",
                          staticStyle: { "text-align": "center" }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-yellow ripple-parent mr-2",
                              attrs: { type: "submit", id: "agent-submit" }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("AGENT.BUTTON.SAVE")) + " "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "col-md-4",
                    staticStyle: { "padding-left": "20px" }
                  },
                  [
                    _c("div", { staticClass: "mb-4" }, [
                      _c("h4", { staticClass: "bold" }, [
                        _vm._v(_vm._s(_vm.$t("MANAGEMENT.EDIT_HISTORY")) + " ")
                      ]),
                      _c(
                        "table",
                        { staticClass: "table table-bordered table-hover" },
                        [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [
                                _vm._v(_vm._s(_vm.$t("MANAGEMENT.DATE")))
                              ]),
                              _c("th", [
                                _vm._v(_vm._s(_vm.$t("MANAGEMENT.EDITED_BY")))
                              ])
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.historyLists, function(list, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "link",
                                      on: {
                                        click: function($event) {
                                          return _vm.getHistoryItem(
                                            list.updatedDate
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm._f("date")(list.updatedDate))
                                      )
                                    ]
                                  )
                                ]),
                                _c("td", [_vm._v(_vm._s(list.updatedBy))])
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ]),
          _c(
            "modal",
            {
              attrs: {
                name: "modalHistory",
                width: "800",
                height: "auto",
                clickToClose: false,
                adaptive: true
              }
            },
            [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.hideModal("modalHistory")
                      }
                    }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×")
                    ])
                  ]
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "modal-body",
                  staticStyle: { height: "80vh", "overflow-y": "auto" }
                },
                [
                  _vm.historyLoading
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "mb-4" }, [
                            _c("h4", { staticClass: "bold" }, [
                              _vm._v(_vm._s(_vm.$t("MANAGEMENT.AGENT_INFO")))
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "Username" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("MANAGEMENT.USERNAME"))
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "Username",
                                        name: "Username",
                                        disabled: ""
                                      },
                                      domProps: {
                                        value: _vm.historyData.username
                                      }
                                    })
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "Name" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("MANAGEMENT.NAME")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "Name",
                                        name: "Name",
                                        disabled: ""
                                      },
                                      domProps: { value: _vm.historyData.name }
                                    })
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "mb-4" }, [
                            _c("h4", { staticClass: "bold" }, [
                              _vm._v(_vm._s(_vm.$t("MANAGEMENT.CONTACT_INFO")))
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "Email" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("MANAGEMENT.EMAIL")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "Email",
                                        name: "Email",
                                        disabled: ""
                                      },
                                      domProps: { value: _vm.historyData.email }
                                    })
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "Phone" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("MANAGEMENT.PHONE")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "Phone",
                                        name: "Phone",
                                        disabled: ""
                                      },
                                      domProps: { value: _vm.historyData.phone }
                                    })
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "mb-4" }, [
                            _c("h4", { staticClass: "bold" }, [
                              _vm._v(_vm._s(_vm.$t("MANAGEMENT.OTHER_INFO")))
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "Referrer" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("MANAGEMENT.REFERRER")
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "Referrer",
                                        placeholder: _vm.$t(
                                          "MANAGEMENT.REFERRER"
                                        ),
                                        disabled: ""
                                      },
                                      domProps: {
                                        value: _vm.historyData.referrer
                                      }
                                    })
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "Remark" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("MANAGEMENT.REMARK")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "Remark",
                                        placeholder: _vm.$t(
                                          "MANAGEMENT.REMARK"
                                        ),
                                        disabled: ""
                                      },
                                      domProps: {
                                        value: _vm.historyData.remark
                                      }
                                    })
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "mb-4" }, [
                            _c("h4", { staticClass: "bold" }, [
                              _vm._v(_vm._s(_vm.$t("MANAGEMENT.STATUS")))
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "suspend" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("MANAGEMENT.BUTTON.SUSPEND")
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "suspend",
                                        placeholder: _vm.$t(
                                          "MANAGEMENT.BUTTON.SUSPEND"
                                        ),
                                        disabled: ""
                                      },
                                      domProps: {
                                        value: _vm.historyData.suspend
                                          ? _vm.$t("YES")
                                          : _vm.$t("NO")
                                      }
                                    })
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "col-sm-6" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "col-4 col-form-label bold",
                                      attrs: { for: "lock" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("MANAGEMENT.BUTTON.LOCK")
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "lock",
                                        placeholder: _vm.$t(
                                          "MANAGEMENT.BUTTON.LOCK"
                                        ),
                                        disabled: ""
                                      },
                                      domProps: {
                                        value: _vm.historyData.lock
                                          ? _vm.$t("YES")
                                          : _vm.$t("NO")
                                      }
                                    })
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("div", { staticClass: "mb-4" }, [
                                _c("h4", { staticClass: "bold" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("MANAGEMENT.SETTING_CREDIT"))
                                  )
                                ]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-sm-12" }, [
                                    _c("div", { staticClass: "form-group row" })
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-12" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "col-4 col-form-label bold",
                                        attrs: { for: "Currency" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.CURRENCY")
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("div", { staticClass: "col-8" }, [
                                      _c("input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          id: "Currency",
                                          name: "Currency",
                                          disabled: ""
                                        },
                                        domProps: {
                                          value: _vm.historyData.currency
                                        }
                                      })
                                    ])
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-12" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "col-4 col-form-label bold",
                                        attrs: { for: "Creditlimit" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.CREDIT")
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("div", { staticClass: "col-8" }, [
                                      _c("input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          id: "Creditlimit",
                                          name: "Creditlimit",
                                          disabled: ""
                                        },
                                        domProps: {
                                          value: _vm.historyData.creditLimit
                                        }
                                      })
                                    ])
                                  ])
                                ])
                              ]),
                              _vm.role == "Company"
                                ? _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold",
                                              attrs: { for: "Typebusiness" }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "MANAGEMENT.POSITION_TYPE"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c("div", { staticClass: "col-8" }, [
                                            _c("input", {
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "Typebusiness",
                                                name: "Typebusiness",
                                                disabled: ""
                                              },
                                              domProps: {
                                                value:
                                                  _vm.historyData.typeBusiness
                                              }
                                            })
                                          ])
                                        ]
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm.role == "Shareholder" ||
                              _vm.role == "Master Agent"
                                ? _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold",
                                              attrs: { for: "Typeagent" }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "MANAGEMENT.POSITION_TYPE"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c("div", { staticClass: "col-8" }, [
                                            _c("input", {
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "Typeagent",
                                                name: "Typeagent",
                                                disabled: ""
                                              },
                                              domProps: {
                                                value: _vm.historyData.typeAgent
                                              }
                                            })
                                          ])
                                        ]
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              (_vm.role == "Shareholder" ||
                                _vm.role == "Master Agent") &&
                              _vm.dataAgentnew.typeAgent == "API"
                                ? _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold",
                                              attrs: { for: "Typeagentapi" }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "MANAGEMENT.BET_TYPE"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c("div", { staticClass: "col-8" }, [
                                            _c("input", {
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "Typeagentapi",
                                                name: "Typeagentapi",
                                                placeholder: _vm.$t(
                                                  "MANAGEMENT.BET_TYPE"
                                                ),
                                                disabled: ""
                                              },
                                              domProps: {
                                                value:
                                                  _vm.historyData.typeAgentAPI
                                              }
                                            })
                                          ])
                                        ]
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-12" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "col-4 col-form-label bold",
                                        attrs: { for: "Givepercent" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("MANAGEMENT.GIVEN_PT")
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("div", { staticClass: "col-8" }, [
                                      _c("input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          id: "Givepercent",
                                          name: "Givepercent",
                                          placeholder: _vm.$t(
                                            "MANAGEMENT.GIVEN_PT"
                                          ),
                                          disabled: ""
                                        },
                                        domProps: {
                                          value: _vm.historyData.yourRate
                                        }
                                      })
                                    ])
                                  ])
                                ])
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-sm-6" },
                              [
                                _c("div", { staticClass: "mb-4" }, [
                                  _c("h4", { staticClass: "bold" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("MANAGEMENT.LIMIT_SETTING"))
                                    )
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c("div", {
                                        staticClass: "form-group row"
                                      })
                                    ])
                                  ])
                                ]),
                                _vm._l(_vm.limitListHistory, function(
                                  t,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "row" },
                                    [
                                      _c("div", { staticClass: "col-sm-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c("label", {
                                              staticClass:
                                                "col-2 col-form-label bold"
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "col-10" },
                                              [
                                                _c("input", {
                                                  staticClass:
                                                    "form-check-input",
                                                  attrs: {
                                                    type: "radio",
                                                    id: t.name + "history",
                                                    name: "limitHistory",
                                                    disabled: t.check
                                                      ? false
                                                      : true
                                                  },
                                                  domProps: { checked: t.check }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "form-check-label bold",
                                                    attrs: {
                                                      for: t.name + "history"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(t.name) +
                                                        " " +
                                                        _vm._s(
                                                          "" +
                                                            _vm.$t(
                                                              "MANAGEMENT.MIN"
                                                            ) +
                                                            " = " +
                                                            t.min +
                                                            " , " +
                                                            _vm.$t(
                                                              "MANAGEMENT.MAX"
                                                            ) +
                                                            " = " +
                                                            t.max +
                                                            ""
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("i", {
                                                      class:
                                                        _vm.historyData.limit >=
                                                        t.value
                                                          ? "fa fa-check text-success"
                                                          : "fa fa-times text-danger"
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        ])
                      ])
                    : _vm._e()
                ]
              )
            ]
          ),
          _c(
            "modal",
            {
              attrs: {
                name: "changePasswordModal",
                height: "auto",
                clickToClose: true,
                adaptive: true
              }
            },
            [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h5",
                  { staticClass: "modal-title col-sm-11" },
                  [
                    _c("center", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("MANAGEMENT.PASSWORD_MODAL_TITLE")) +
                          " "
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button", id: "close-sharePercent" },
                    on: {
                      click: function($event) {
                        return _vm.hideModal("changePasswordModal")
                      }
                    }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×")
                    ])
                  ]
                )
              ]),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-3 col-form-label bold",
                          attrs: { for: "Password" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("MANAGEMENT.NEW_PASSWORD")) + " "
                          ),
                          _c("span", { staticClass: "required" }, [_vm._v("★")])
                        ]
                      ),
                      _c("div", { staticClass: "col-7" }, [
                        (_vm.flagDisplayAsPassword.default
                          ? "password"
                          : "text") === "checkbox"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.dataAgentnew.password,
                                  expression: "dataAgentnew.password"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "Password",
                                name: "Password",
                                placeholder: _vm.$t("MANAGEMENT.PASSWORD"),
                                autocomplete: "new-password",
                                type: "checkbox"
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.dataAgentnew.password
                                )
                                  ? _vm._i(_vm.dataAgentnew.password, null) > -1
                                  : _vm.dataAgentnew.password
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.dataAgentnew.password,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.dataAgentnew,
                                          "password",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.dataAgentnew,
                                          "password",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.dataAgentnew, "password", $$c)
                                  }
                                }
                              }
                            })
                          : (_vm.flagDisplayAsPassword.default
                              ? "password"
                              : "text") === "radio"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.dataAgentnew.password,
                                  expression: "dataAgentnew.password"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "Password",
                                name: "Password",
                                placeholder: _vm.$t("MANAGEMENT.PASSWORD"),
                                autocomplete: "new-password",
                                type: "radio"
                              },
                              domProps: {
                                checked: _vm._q(_vm.dataAgentnew.password, null)
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.dataAgentnew,
                                    "password",
                                    null
                                  )
                                }
                              }
                            })
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.dataAgentnew.password,
                                  expression: "dataAgentnew.password"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "Password",
                                name: "Password",
                                placeholder: _vm.$t("MANAGEMENT.PASSWORD"),
                                autocomplete: "new-password",
                                type: _vm.flagDisplayAsPassword.default
                                  ? "password"
                                  : "text"
                              },
                              domProps: { value: _vm.dataAgentnew.password },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.dataAgentnew,
                                    "password",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                        _c("small", { staticClass: "text-muted form-text" }, [
                          _vm._v(_vm._s(_vm.$t("MANAGEMENT.PASSWORD_CHECK")))
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "toggle-password field-icon",
                            attrs: { toggle: "#Password" }
                          },
                          [
                            _c("i", {
                              staticClass: "far fa-eye",
                              on: {
                                click: function($event) {
                                  return _vm.toggleShowPassword("default")
                                }
                              }
                            })
                          ]
                        )
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("center", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-yellow ripple-parent mr-2",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.sendNewPassword()
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("AGENT.BUTTON.CHANGE_PASSWORD_SAVE")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-yellow ripple-parent mr-2",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.hideModal("changePasswordModal")
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("AGENT.BUTTON.CHANGE_PASSWORD_CLOSE")
                                ) +
                                " "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "form-group row" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("h4", { staticClass: "bold" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group row" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("h4", { staticClass: "bold" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group row" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("h4", { staticClass: "bold" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group row" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("h4", { staticClass: "bold" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group row" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("h4", { staticClass: "bold" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group row" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }