































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import EditAgentComponent from "./script.component";

@Component
export default class EditAgent extends Mixins(EditAgentComponent) {}

