import { render, staticRenderFns } from "./view.component.vue?vue&type=template&id=cddc90fc&"
import script from "./view.component.vue?vue&type=script&lang=ts&"
export * from "./view.component.vue?vue&type=script&lang=ts&"
import style0 from "./view.component.vue?vue&type=style&index=0&lang=css&"
import style1 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/iampoker-new-ui/iampoker-new-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cddc90fc')) {
      api.createRecord('cddc90fc', component.options)
    } else {
      api.reload('cddc90fc', component.options)
    }
    module.hot.accept("./view.component.vue?vue&type=template&id=cddc90fc&", function () {
      api.rerender('cddc90fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Agent/EditAgent/view.component.vue"
export default component.exports