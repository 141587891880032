import { Component, Vue } from "vue-property-decorator";
import EditAgentComponent from './view.component.vue';

@Component({
    components: {
        EditAgentComponent
    }
})
class EditAgent extends Vue {
    public role = this.$store.state.profile.roles[0] 
}
export default EditAgent